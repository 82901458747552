































import { Component, Vue, Watch } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import FaceRadio from '@/components/molecules/FaceRadio.vue'
import HomeworkList from '@/components/organisms/HomeworkList.vue'
import SelectWide from '@/components/molecules/SelectWide.vue'
import moment from 'moment'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    FillRound,
    FaceRadio,
    HomeworkList,
    SelectWide,
  },
})
export default class Review extends Vue {
  private lessonId = this.$route.params.id
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode || 'TJ'
  }

  private date = moment().locale('ja').format('YYYY年MM月DD日')

  private homeworkDatas: { code: string; subject: string; content: string; url: string; disabled: boolean }[] = []

  private lookBackResult = 1

  private lookBackComment = ''

  private lessonUserId = 0

  private className = ''

  private isProcessing = false

  private selectedLessonId = this.lessonId

  private lookBackEnableOptions: { text: string; value: string }[] = []

  private get commentError(): string {
    return this.lookBackComment.length > 300 ? '300文字以内で入力してください' : ''
  }

  private get colortype(): string {
    return this.lookBackComment.length > 0 && !this.commentError ? 'gradation-orange' : 'pointer-events-none'
  }

  private async loadLessonUser() {
    const params = [`lessonId=${this.selectedLessonId}`, `userId=${this.userId}`, `classMode=${this.classMode()}`]
    Vue.prototype.$http.httpWithToken
      .get(`/lessonUsers/lookBack?${params.join('&')}`)
      .then((res: any) => {
        this.lessonUserId = res.data.lessonUserId
        this.className = res.data.className
        this.lookBackResult = res.data.lookBackResult ? res.data.lookBackResult : 1
        this.lookBackComment = res.data.lookBackComment ? res.data.lookBackComment : ''
      })
      .catch((error: any) => {
        // エラー処理
        this.$router.push('/error/error400')
        throw new Error(error)
      })
  }

  private async loadHomework() {
    this.homeworkDatas = []

    const params = [`lessonId=${this.selectedLessonId}`, `userId=${this.userId}`, `classMode=${this.classMode()}`]
    Vue.prototype.$http.httpWithToken
      .get(`/lessons/homework?${params.join('&')}`)
      .then((res: any) => {
        res.data.forEach((item: { subjectCode: string; subjectName: string; curriculumSUnits: any[] }) => {
          if (item.curriculumSUnits.length > 0) {
            item.curriculumSUnits.forEach((unitName: string) => {
              this.homeworkDatas.push({
                code: item.subjectCode,
                subject: item.subjectName,
                content: unitName,
                url: '',
                disabled: true,
              })
            })
          } else {
            this.homeworkDatas.push({
              code: item.subjectCode,
              subject: item.subjectName,
              content: '宿題生成中です。',
              url: '',
              disabled: true,
            })
          }
        })
      })
      .catch((error: any) => {
        // エラー処理
        this.$router.push('/error/error400')
        throw new Error(error)
      })
  }

  private async loadLookBackEnable() {
    const params = [`lessonId=${this.lessonId}`, `userId=${this.userId}`]
    Vue.prototype.$http.httpWithToken
      .get(`/lessonUsers/lookBackEnable?${params.join('&')}`)
      .then((res: any) => {
        if (res.data.length > 1) {
          this.lookBackEnableOptions = res.data.map((lessonUser: { lessonId: number; className: string }) => {
            return { text: lessonUser.className, value: lessonUser.lessonId.toString() }
          })
        } else {
          // 1件の場合はプルダウン非表示
          this.lookBackEnableOptions = []
        }
      })
      .catch((error: any) => {
        // エラー処理
        this.$router.push('/error/error400')
        throw new Error(error)
      })
  }

  private async updateLessonUser() {
    if (this.isProcessing === true) return
    this.isProcessing = true
    await Vue.prototype.$http.httpWithToken
      .patch(`/lessonUsers/${this.lessonUserId}`, {
        classMode: this.classMode(),
        lookBackResult: this.lookBackResult,
        lookBackComment: this.lookBackComment,
      })
      .then(() => {
        this.$router.push('/student/dashboard')
      })
      .finally(() => {
        this.isProcessing = false
      })
  }

  private async loadDatas() {
    this.loadLessonUser()
    this.loadHomework()
    this.loadLookBackEnable()
  }

  private async mounted(): Promise<void> {
    this.loadDatas()
  }

  @Watch('selectedLessonId')
  onSelectedLessonChange() {
    this.loadDatas()
  }
}
